import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { toast } from 'react-hot-toast'
import { send } from 'emailjs-com';

export interface PageContactProps {
  className?: string;
}

const info = [
  // {
  //   title: "🗺 ADDRESS",
  //   desc: "Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter",
  // },
  {
    title: "💌 EMAIL",
    desc: "sandraconciergerie06@gmail.com",
  },
  {
    title: "☎ PHONE",
    desc: "+33660755715",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const [senderName, setSenderName] = useState('')
  const [senderEmail, setSenderEmail] = useState('')
  const [senderMessage, setSenderMessage] = useState('')


const sendmail = () => {

send(
  process.env.REACT_APP_EMAILJS_SERVICE_ID || '', 
  process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '', 
  {senderName, senderEmail, senderMessage}, 
  process.env.REACT_APP_EMAILJS_USER_ID || '' 
)
.then((response) => {

  if ( response.status === 200 ) {
    toast.success('message sent successfully')
      
    setSenderName('')

    setSenderEmail('')

    setSenderMessage('')
    
  } else {
    
    toast.error(`${response.text}. So message not sent`)
  }
 
})
.catch((error) => {toast.error('Error: message not sent', error.message) })


}


  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact us</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
            </div>
            <div>
            <form className="grid grid-cols-1 gap-6">
                <label className="block">
                  <Label>Full name</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    onChange={(e) => setSenderName(e.target.value)}
                    value={senderName}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={(e) => setSenderEmail(e.target.value)}
                    value={senderEmail}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea className="mt-1" rows={14}  onChange={(e) => setSenderMessage(e.target.value)} value={senderMessage}/>
                </label>
                <div>
                  <ButtonPrimary onClick={sendmail} type="button">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;
