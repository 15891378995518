import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionGridFeaturePlaces from "components/SectionGridFeaturePlaces/SectionGridFeaturePlaces";
export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Airb&Key</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
          

        {/* SECTION */}
        <div className="relative xl:pt-8">
          
          <SectionGridFeaturePlaces />

          
        {/* SECTION CLIENTS SAY */}
        <div className="container pt-10 pb-24 lg:pt-16 lg:pb-32">
          {/* <BackgroundSection /> */}
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>

        </div>
    
      </div>
    </div>
  );
};

export default ListingStayPage;
