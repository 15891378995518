import React, { useEffect, useState } from "react";
import { MapIcon } from "@heroicons/react/solid";
import {Link} from "react-router-dom";
import { MapIcon as NoMApIcon  } from "@heroicons/react/outline";
export interface SwitchMapModeProps {
  className?: string;
}
const SwitchMapMode: React.FC<SwitchMapModeProps> = ({ className = "" }) => {
  const [isMapMode, setIsMapMode] = useState(false);

  useEffect(() => {
    if (
      localStorage.map === "mapOff" ||
      !("map" in localStorage) 
    ) {
      turnOffMap();
    }  
 
  }, []);
  const turnOnMap = () => {
    setIsMapMode(true);
    const root = document.querySelector("html");
    if (!root) return;
    !root.classList.contains("mapOn") && root.classList.add("mapOn");
    localStorage.map = "mapOn";
  };

  const turnOffMap = () => {
    setIsMapMode(false);
    const root = document.querySelector("html");
    if (!root) return;
    root.classList.remove("mapOn");
    localStorage.map = "mapOff";
  };

  function _toogleMapMode() {
    if ( localStorage.map === "mapOff") {
      turnOnMap();
    } else {
      turnOffMap();
    }
  }

  return (
    <button
      onClick={_toogleMapMode}
      className={`text-2xl md:text-3xl w-12 h-12 xl:inline hidden rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center ${className}`}
    >
      <span className="sr-only">Enable map mode</span>
      {isMapMode ? (
        <Link to={'/'} >
        <MapIcon className="xl:w-10 w-7 xl:h-10 h-7" aria-hidden="true" />
        </Link>
      ) : (
         <Link to={'/listing-stay-map'} >
        <NoMApIcon className="xl:w-10 w-7 xl:h-10 h-7" aria-hidden="true" />
        </Link>
      )}
    </button>
  );
};

export default SwitchMapMode;
