import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import { useEffect } from "react";
import clientSayMain from "images/clientSayMain.png";
import clientSay1 from "images/clientSay1.png";
import clientSay2 from "images/clientSay2.png";
import clientSay3 from "images/clientSay3.png";
import clientSay4 from "images/clientSay4.png";
import clientSay5 from "images/clientSay5.png";
import clientSay6 from "images/clientSay6.png";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";

export interface SectionClientSayProps {
  className?: string;
  uniqueClassName: string;
}

const DEMO_DATA = [
  {
    id: 1,
    clientName: "Cameron G.",
    clientAddress: "UK",
    content:
      "Very clean and great views. Good location for the centre of Cannes, approx 15 mins walk. The host was very flexible with check in/out times.",
  },
  {
    id: 2,
    clientName: "Andrew C.",
    clientAddress: "UK",
    content:
      "Beautiful apartment in a perfect location. Very quiet yet a shortish walk to the Croisette or Old Town. And extremely helpful managing agent.",
  },
  {
    id: 3,
    clientName: "Ali B.",
    clientAddress: "Switzerland",
    content:
      "merci infiniment pour votre accueil. Un plaisir de vous revoir bientôt.",
  },
  {
    id: 4,
    clientName: "Aedin w.",
    clientAddress: "UK",
    content:
      "Un appartement impeccable idéalement situé, avec des hôtes très attentifs, réactifs et amicaux.",
  },
  {
    id: 5,
    clientName: "Jean-Paul B.",
    clientAddress: "France",
    content:
      "Très bel appartement propre et correspondant à l'annonce. Idéalement situé en centre-ville, accueil très sympathique.",
  },
  {
    id: 6,
    clientName: "Päivi P.",
    clientAddress: "Finland",
    content:
      "The listing matched the description accurately, and the early check-in was a success. Thank you for that. The communication was smooth.",
  },
];

const SectionClientSay: FC<SectionClientSayProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}`;

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1,
        }).mount();
      }, 10);
    }
  }, []);

  const renderBg = () => {
    return (
      <div className="hidden md:block">
        <img className="absolute top-9 -left-20" src={clientSay1} alt="" />
        <img
          className="absolute bottom-[100px] right-full mr-40"
          src={clientSay2}
          alt=""
        />
        <img
          className="absolute top-full left-[140px]"
          src={clientSay3}
          alt=""
        />
        <img
          className="absolute -bottom-10 right-[140px]"
          src={clientSay4}
          alt=""
        />
        <img
          className="absolute left-full ml-32 bottom-[80px]"
          src={clientSay5}
          alt=""
        />
        <img className="absolute -right-10 top-10 " src={clientSay6} alt="" />
      </div>
    );
  };

  return (
    <div
      className={`nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      <Heading desc="Let's see what people think of Airb&Key" isCenter>
        Good news from far away
      </Heading>
      <div className="relative max-w-2xl mx-auto md:mb-16">
        {renderBg()}
        <img className="mx-auto" src={clientSayMain} alt="" />
        <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
          <img
            className="absolute -mr-16 opacity-50 md:opacity-100 lg:mr-3 right-full top-1"
            src={quotationImg}
            alt=""
          />
          <img
            className="absolute -ml-16 opacity-50 md:opacity-100 lg:ml-3 left-full top-1"
            src={quotationImg2}
            alt=""
          />
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              {DEMO_DATA.map((item) => (
                <li
                  key={item.id}
                  className="flex flex-col items-center text-center glide__slide"
                >
                  <span className="block text-2xl">{item.content}</span>
                  <span className="block mt-8 text-2xl font-semibold">
                    {item.clientName}
                  </span>
                  <div className="flex items-center mt-2 space-x-2 text-lg text-neutral-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span>{item.clientAddress}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="flex items-center justify-center mt-10 glide__bullets"
            data-glide-el="controls[nav]"
          >
            {DEMO_DATA.map((item, index) => (
              <button
                key={item.id}
                className="w-2 h-2 mx-1 rounded-full glide__bullet bg-neutral-300 focus:outline-none"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionClientSay;
