import rightImg from "images/our-features.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About Airb&Key</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-40">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="L'histoire d'airbnkey commence en 2018. Au départ nous avions quelques appartements à Cannes en gestion locative saisonnière. Notre professionnalisme et les recommandations de nos clients auprès de leur propre connaissance ont fait grandir notre petite structure familiale. Ainsi, devant une demande grandissante, l'affaire familiale se transforme aujourd'hui en société.


          Ce site web est la vitrine des appartements que nous gérons.
          Vous voulez louer votre logement en location saisonnière en toute sécurité ? Rien de plus simple, on s'occupe de tout.
          
          Vous avez le choix entre 2 options :
          
          1. économique. vous confiez votre bien directement à Airb&Key dans le cadre d'un forfait qui dépendra des caractéristiques de votre bien.
          
          - votre annonce est déjà créée sur Airbnb et vous préférez continuer à gérer votre calendrier. Dans ce cas, nous nous occupons de tout le reste : contact client, arrivée, départ, ménage, linge.
          
          - vous préférez une délégation totale. Dans ce cas nous gérons tout de A à Z: création de l'annonce sur Airbnb, gestion du calendrier de réservation, de la communication avec les clients, leur arrivée, leur départ, le ménage et le linge
          
          2. maxi.vous voulez une visibilité maximale sur les principaux sites de réservation en plus d'Airbnb, et vous voulez bénéficier des prestations de notre partenaire ( synchronisation des calendriers des différents sites, étude de marché pour optimiser la rentabilité de votre appartement, service en cas de litige, service assurance, etc...). Dans ce cas , nous vous mettons en relation avec notre partenaire qui placera votre annonce sur les plus grands sites de réservation. "
        />

        <SectionFounder />
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div> */}

        {/* <SectionStatistic /> */}

        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageAbout;
